<template>
  <div id="shuttle_ticket_page">
    <inner-page-title title="Live Info"></inner-page-title>
    <div ref="google_map" style="height: calc(100vh - 240px);"></div>
    
    <!-- Fixed header map -->
    <!-- <div class="fixed-header-map">
      <div>
        sdf
      </div>
    </div> -->
    
    <div class="shuttle-info">
      <div class="container" style="margin-bottom: 10px;">

        <div style="display: flex; align-items: center; justify-content: space-between;">
          <h4>City Center Shuttle</h4>
          
          <span class="time-left">Due in 3 min</span>
        </div>

        <base-spacer space="3px"></base-spacer>

        <div class="input-group">
          <span>Direction</span>
          <el-select v-model="direction" placeholder="Direction">
            <el-option label="To the office" value="go"></el-option>
            <el-option label="Home" value="back"></el-option>
          </el-select>
        </div>

        <div class="input-group">
          <span>Stop</span>
          <el-select v-model="stop" placeholder="Direction">
            <el-option label="St. Stephen Green East" value="1"></el-option>
            <el-option label="Leeson Street Bridge" value="2"></el-option>
            <el-option label="Morehampton Road" value="3"></el-option>
            <el-option label="Opposite Donnybrook Fire Station" value="4"></el-option>
            <el-option label="Donnybrook Church" value="5"></el-option>
            <el-option label="Stillorgan Road" value="6"></el-option>
            <el-option label="Brewery Road" value="7"></el-option>
            <el-option label="Central Park" value="8"></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import initGoogleMaps from '@/utils/gmaps';

export default {
  props: {
    id: { type: String }
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    domain: process.env.VUE_APP_DOMAIN,
    direction: "go",
    stop: "1",
    waypoints: [
      {
        location: "St. Stephen Green East, Dublin",
        stopover: true
      },
      {
        location: "Leeson Street Bridge, Dublin",
        stopover: true
      },
      {
        location: "Morehampton Road, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Fire Station, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Church, Dublin",
        stopover: true
      },
      {
        location: "Brewery Road, Dublin",
        stopover: true
      }
    ]
  }),

  async mounted() {
    let google = await initGoogleMaps();
    let directionsService = new google.maps.DirectionsService;
    let directionsRenderer = new google.maps.DirectionsRenderer;

    this.map = new google.maps.Map(this.$refs.google_map, {
      zoom: 12,
      center: { lat: 53.26, lng: -6.26 },
      // mapTypeId: 'satellite',
      disableDefaultUI: true
    });

    directionsRenderer.setMap(this.map);

    directionsService.route({
      origin: "D’Olier Street, Dublin",
      destination: "Central Park, Sandyford",
      waypoints: this.waypoints,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, function(response, status) {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      }
    });


    new google.maps.Marker({
      position: new google.maps.LatLng(53.336159,-6.2578312),
      icon: {
        url: this.publicPath + 'img/shuttle-icon.svg',
        scaledSize: new google.maps.Size(40, 40),
      },
      map: this.map
    });
  }
}
</script>

<style lang="scss" scoped>
  #shuttle_ticket_page {
    .shuttle-info {
      position: fixed;
      bottom: 0;
      min-height: 200px;
      z-index: 100;
      background-color: white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0px -4px 12px 2px rgba(0,0,0,0.1);
      border: 0;
      width: 100%;
    }

    .fixed-header-map {
      position: fixed;
      top: 70px;
      right: 10px;
      z-index: 99;
      display: flex;
      justify-content: center;
      padding: 0 10px;
      border: 1px solid red;
    }

    .time-left {
      border: 1px solid #EB4E3F;
      background-color: #EB4E3F;
      padding: 5px 15px;
      border-radius: 5px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      color: white;
      font-weight: 400;
    }
  }
</style>