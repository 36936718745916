var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "shuttle_ticket_page" } },
    [
      _c("inner-page-title", { attrs: { title: "Live Info" } }),
      _c("div", {
        ref: "google_map",
        staticStyle: { height: "calc(100vh - 240px)" }
      }),
      _c("div", { staticClass: "shuttle-info" }, [
        _c(
          "div",
          {
            staticClass: "container",
            staticStyle: { "margin-bottom": "10px" }
          },
          [
            _vm._m(0),
            _c("base-spacer", { attrs: { space: "3px" } }),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("span", [_vm._v("Direction")]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Direction" },
                    model: {
                      value: _vm.direction,
                      callback: function($$v) {
                        _vm.direction = $$v
                      },
                      expression: "direction"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "To the office", value: "go" }
                    }),
                    _c("el-option", { attrs: { label: "Home", value: "back" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("span", [_vm._v("Stop")]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Direction" },
                    model: {
                      value: _vm.stop,
                      callback: function($$v) {
                        _vm.stop = $$v
                      },
                      expression: "stop"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "St. Stephen Green East", value: "1" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Leeson Street Bridge", value: "2" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Morehampton Road", value: "3" }
                    }),
                    _c("el-option", {
                      attrs: {
                        label: "Opposite Donnybrook Fire Station",
                        value: "4"
                      }
                    }),
                    _c("el-option", {
                      attrs: { label: "Donnybrook Church", value: "5" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Stillorgan Road", value: "6" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Brewery Road", value: "7" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Central Park", value: "8" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between"
        }
      },
      [
        _c("h4", [_vm._v("City Center Shuttle")]),
        _c("span", { staticClass: "time-left" }, [_vm._v("Due in 3 min")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }